import PlayVideoButton from "@components/elements/PlayVideo/PlayVideoButton"
import Slider from "@components/elements/Slider/Slider"
import React from "react"
import { Container } from "react-bootstrap"
import { Helmet } from "react-helmet"
import "./ReviewsSlider.scss"
import { Script } from "gatsby"

const ReviewsSlider = ({ module }) => {
  if (!module?.reviews) return null

  const reviews = module?.reviews
  return (
    <>
      <div className="reviews-slider-wrap section-m">
        <Container className="reviews-slider-container">
          <Slider size="sm">
            {reviews?.map((review) => (
              <div className="review-item" key={review.id}>
                <div className="img-section">
                  <img onContextMenu={(e) => e.preventDefault()} draggable="false" src={review?.image?.url} alt="review-img" />
                  {review.video_url && (
                    <PlayVideoButton videoUrl={review.video_url} />
                  )}
                </div>
                <div className="review-content">
                  <p className="name">{review?.name}</p>
                  <p className="description">{review?.description}</p>
                </div>
              </div>
            ))}
          </Slider>
        </Container>
      </div>
      <div className="reviews-widget-wrap section-p">
        <Container className="reviews-widget-container">
          {/* <h2 className="title">Reviews from our valued customers</h2> */}
          <Helmet>
            <Script
              src="https://apps.elfsight.com/p/platform.js"
              strategy="off-main-thread"
            />
          </Helmet>
          <div className="elfsight-app-8ccc50aa-9d0f-4955-88f3-d5bebaec5d78" />
        </Container>
      </div>
    </>
  )
}

export default ReviewsSlider
