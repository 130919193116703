import React from "react"
import "./Timeline.scss"
import { Container } from "react-bootstrap"
import { parseHTML } from "@lib/utils"
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component"
import "react-vertical-timeline-component/style.min.css"
import CTALink from "@components/elements/CTALink"
import clsx from "clsx"
import Animation from "@components/elements/Animation"
import useDeviceMedia from "@hooks/useDeviceMedia"

const Timeline = ({ module }) => {
  const { isTablet } = useDeviceMedia()

  React.useEffect(() => {
    //  select the first item with class "timeline-item-wrap"
    const firstTimelineItem = document.querySelector(".timeline-item")

    // get height of the timeline item
    const timelineItemHeight = firstTimelineItem?.clientHeight

    const height = timelineItemHeight / 2

    // add a css rule to the document head
    const addRule = ((style) => {
      const { sheet } = document.head.appendChild(style)
      return (selector, css) => {
        const propText =
          typeof css === "string"
            ? css
            : Object.keys(css)
                .map((p) => {
                  return `${p}:${p === "content" ? `'${css[p]}'` : css[p]}`
                })
                .join(";")
        sheet.insertRule(`${selector}{${propText}}`, sheet.cssRules.length)
      }
    })(document.createElement("style"))

    // add top value to the timeline item
    addRule(".vertical-timeline::before", {
      top: `${height}px`,
    })

    const timelineSection = document.querySelector(".timeline-section")
    // add margin bottom to the timeline section
    timelineSection.style.paddingBottom = `${height}px`

    const bottomCircle = document.querySelector(".bottom-circle")
    bottomCircle.style.top = `${height}px`
  }, [])

  if (!module) return null

  const { heading, bottom_title, title, description, timeline, ctas } = module

  return (
    <Animation className="timeline-wrap section-p">
      <Container className="timeline-container">
        <div className="content-section">
          <p className="heading">{heading}</p>
          <h2 className="title">{title}</h2>
        </div>
        <div className="timeline-section">
          <VerticalTimeline animate={false}>
            {timeline?.map((item, index) => (
              <VerticalTimelineElement
                className="timeline-item-wrap"
                key={item.id}
              >
                <Animation
                  animateInType={
                    // eslint-disable-next-line no-nested-ternary
                    isTablet ? "right" : index % 2 === 0 ? "left" : "right"
                  }
                  className="timeline-item"
                >
                  {item.image && (
                    <img onContextMenu={(e) => e.preventDefault()} draggable="false" src={item.image.url} alt="timeline-img" />
                  )}
                  <div className="timeline-item-content">
                    <p className="date">{item.date}</p>
                    <p className="title">{item.title}</p>
                    <div className="description">
                      {parseHTML(item.description?.data?.description)}
                    </div>
                  </div>
                </Animation>
              </VerticalTimelineElement>
            ))}
          </VerticalTimeline>
          <div className="bottom-circle" />
        </div>
        <Animation className="cta-content-section">
          <h2 className="cta-title">{bottom_title}</h2>
          <div className="cta-description">
            {parseHTML(description?.data.description)}
          </div>
          <div className="cta-section">
            {ctas?.map((cta, index) => (
              <CTALink
                cta={cta}
                key={cta.id}
                className={clsx(
                  "button",
                  index === 0 ? "button-orange" : "button-gray"
                )}
              />
            ))}
          </div>
        </Animation>
      </Container>
    </Animation>
  )
}

export default Timeline
