import React from "react"
import ReactSelect from "react-select"
import "./Pagination.scss"
import useDeviceMedia from "@hooks/useDeviceMedia"
import Animation from "@components/elements/Animation"
import $ from "jquery"
import ChevronLeft from "@components/icons/ChevronLeft"
import downArrow from "@images/icons/chevron-down.svg"
import ChevronRight from "@components/icons/ChevronRight"
// import downArrow from "../../images/icons/CaretDown.svg"

const Pagination = ({
  itemsPerPage,
  totalItems,
  currentPage,
  setCurrentPage,
  stopScroll
}) => {
  const { isMobile } = useDeviceMedia()

  const pageNumbers = []
  const pageNumberOptions = []

  // Generate page numbers
  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i)
    pageNumberOptions.push({ value: i, label: i })
  }

  const totalPageNumbers = pageNumbers?.length

  const handlePageChange = (value) => {
    // window.scrollTo({ top: 0, behavior: "smooth" })
    if(!stopScroll){
    $("html, body").animate({ scrollTop: isMobile ? 230 : 280 }, 600)
    }
    // handle change for dropdown select
    if (typeof value === "object") setCurrentPage(value.value)
    // handle change for number based page change
    else if (typeof value === "number") setCurrentPage(value)
    else
      switch (value) {
        case "back":
          setCurrentPage((page) => page - 1)
          break
        case "next":
          setCurrentPage((page) => page + 1)
          break
        default:
          break
      }
  }

  if (totalPageNumbers < 2) return null

  return (
    <nav className="pagination-wrapper">
      <Animation className="pagination-container">
        <button
          onClick={() => handlePageChange("back")}
          className={`button button-white pagination-button button-back ${
            currentPage <= 1 ? "button-disabled" : ""
          }`}
          disabled={currentPage <= 1}
        >
          <ChevronLeft />
          {!isMobile && <span>Back</span>}
        </button>

        <div className="pagination-select-wrap">
          <span className="page-text">Page:</span>
          <ReactSelect
            options={pageNumberOptions}
            isSearchable={false}
            className="pagination-select"
            classNamePrefix="pagination-select"
            components={{
              // eslint-disable-next-line react/no-unstable-nested-components
              DropdownIndicator: () => <img onContextMenu={(e) => e.preventDefault()} draggable="false" src={downArrow} alt="arrow" />,
            }}
            value={{ value: currentPage, label: currentPage }}
            onChange={handlePageChange}
          />
          <span className="page-text">of {totalPageNumbers}</span>
        </div>

        <button
          onClick={() => handlePageChange("next")}
          className={`button button-white pagination-button button-next ${
            currentPage >= totalPageNumbers ? "button-disabled" : ""
          }`}
          disabled={currentPage >= totalPageNumbers}
        >
          {!isMobile && <span>Next</span>}
          <ChevronRight />
        </button>
      </Animation>
    </nav>
  )
}

export default Pagination
